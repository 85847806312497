/* eslint-env serviceworker */
/* eslint no-restricted-globals: 1 */

import config from '~/configuration';

const {
  fetchHandlerProbe,
  privateApiRoot,
  publicApiRoot,
  signalRHubUrl,
  functionHostKey,
  publicFunctionHostKey,
} = config;

self.addEventListener('install', () => {
  self.skipWaiting();
});

self.addEventListener('activate', (event) => {
  event.waitUntil(self.clients.claim());
});

const getAuthTokenHeader = async () => {
  const allClients = await self.clients.matchAll();
  const client = allClients.filter((client) => client.type === 'window')[0];

  if (!client) {
    return null;
  }

  const channel = new MessageChannel();
  client.postMessage(
    {
      action: 'getAuthTokenHeader',
    },
    [channel.port1],
  );

  return new Promise((resolve, reject) => {
    channel.port2.onmessage = (event) => {
      if (event.data.error) {
        console.error('Port error', event.error);
        reject(event.data.error);
      }

      resolve(event.data.token);
    };
  });
};

const getPrivateResponse = async (request) => {
  const headers = {};
  for (let entry of request.headers) {
    headers[entry[0].toLowerCase()] = entry[1];
  }

  const token = await getAuthTokenHeader();

  if (token === null) {
    return new Response(null, {
      status: 401,
      statusText: 'Unauthorized',
    });
  }

  headers['authorization'] = `Bearer ${token}`;
  headers['x-functions-key'] = functionHostKey;

  let body;
  switch (request.method) {
    case 'GET':
    case 'HEAD':
      body = null;
      break;
    default:
      body = await request.text();
      break;
  }

  return fetch(
    new Request(request.url, {
      method: request.method,
      headers,
      body: body?.length > 0 ? body : null,
    }),
  );
};

const getPublicResponse = async (request) => {
  const headers = {};
  for (let entry of request.headers) {
    headers[entry[0].toLowerCase()] = entry[1];
  }

  headers['x-functions-key'] = publicFunctionHostKey;

  let body;
  switch (request.method) {
    case 'GET':
    case 'HEAD':
      body = null;
      break;
    default:
      body = await request.text();
      break;
  }

  return fetch(
    new Request(request.url, {
      method: request.method,
      headers,
      body: body?.length > 0 ? body : null,
    }),
  );
};

const fetchHandler = (fetchEvent) => {
  const request = fetchEvent.request;

  if (request.url.startsWith(fetchHandlerProbe)) {
    fetchEvent.respondWith(
      new Response(null, {
        status: 200,
        statusText: 'Intercepted',
      }),
    );
    return;
  }

  if (
    request.url.indexOf(privateApiRoot) === 0 ||
    request.url.indexOf(signalRHubUrl) === 0
  ) {
    fetchEvent.respondWith(getPrivateResponse(request));
    return;
  }

  if (request.url.indexOf(publicApiRoot) === 0) {
    fetchEvent.respondWith(getPublicResponse(request));
    return;
  }

  fetchEvent.respondWith(fetch(request));
};

self.addEventListener('fetch', fetchHandler);
